.gridItem {
  transition: var(--transitionStyleButton);
}

.gridItem:hover {
  transform: scale(1.02);
  box-shadow: var(--boxShadowListingCard);
}

.shopLogo {
  max-width: 100%;
  max-height: 44px;
  object-fit: contain;
}

.shopImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.footer {
  background-color: var(--gray10);
  border-top: 2px solid var(--gray20);
  width: 100%;
  z-index: var(--zIndexTopbar);
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  margin: 6px;
}

.sticky {
  position: fixed;
  bottom: 0;
}
