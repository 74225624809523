/* Desktop */
.desktopRoot {
  width: 100%;
  height: var(--topbarHeightDesktop);
  background-color: var(--gray10);
}

.categoryButton:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.selectedCategoryDesktop {
  background-color: var(--teal80);
  height: 32px;
}

/* Mobile */
.mobileRoot {
  height: var(--topbarHeight);
  background-color: var(--gray10);
}

.menuIcon {
  border-width: 0;
  background-color: transparent;
}

.shopIcon {
  max-width: 100%;
  max-height: 44px;
}

@media only screen and (min-width: 768px) {
  .shopIcon {
    max-width: 236px;
  }
}

.selectedCategoryMobileMenu {
  background-color: var(--teal80);
  height: 32px;
  width: 98px;
}
